<template>
    <div>
        <Header />
        <mdb-parallax style="margin-top: -13px"
            src="/img/bgs/bg1.jpg"
            height="full"
            factor="1"
            >
            <mdb-mask overlay="blue-slight" flexCenter>
                <mdb-row class="mb-5 pb-5 text-center white-text">
                    <mdb-col md="12" xl="12" class="mx-auto">
                        <h1 class="display-4 font-weight-bold animated fadeInDown">Acerca de Arvore</h1>
                    </mdb-col>
                </mdb-row>
            </mdb-mask>
        </mdb-parallax>
        <section>
            <mdb-container class="mt-5 mb-5 pt-5 pb-5">
                <mdb-row class="pb-4">
                    <mdb-col>
                        <mdb-accordion :panes="panesPicture" picture :open="0"></mdb-accordion> 
                    </mdb-col>
                </mdb-row>
                <hr class="pt-4">
                <mdb-row>
                    <mdb-col>
                        <section>
                            <mdb-card class="card-image" style="background-image: url(https://mdbootstrap.com/img/Photos/Others/forest2.jpg)">
                            <div class="text-white text-center rgba-stylish-strong py-5 px-4">
                                <div class="py-5">
                                <h2 class="card-title h2 my-4 py-2">Nuestro Objetivo</h2>
                                <p class="mb-4 pb-2 px-md-5 mx-md-5 text-justify">Promover un equilibrio entre el desarrollo humano y la preservación de nuestro entorno natural. Mediante la implementación de soluciones innovadoras y la búsqueda constante de la sostenibilidad, buscamos proteger la biodiversidad, mitigar el cambio climático y construir un futuro más resiliente para las generaciones venideras.</p>
                                <button @click="$store.commit('_toggleContactModal')" class="btn btn-arvore" ><i class="fa fa-envelope"></i> Contáctanos</button>
                                </div>
                            </div>
                            </mdb-card>
                        </section>
                    </mdb-col>
                </mdb-row>
            </mdb-container>
        </section>
        <Footer/>
    </div>
</template>
<script>
    import Header from '../components/Header';
    import { mdbContainer, mdbRow, mdbCol, mdbCard, mdbJumbotron, mdbCardTitle, mdbView, mdbIcon, mdbMedia, mdbMediaImage, mdbMediaBody, mdbParallax, mdbMask, mdbAccordion, mdbBtn } from "mdbvue";
    import Footer from "./Footer";

    export default {
        name: "AboutUs",
        components: {
            Header,
            mdbContainer, 
            mdbRow,
            mdbCol,
            mdbCard,
            mdbJumbotron, 
            mdbCardTitle, 
            mdbView, 
            mdbIcon,
            Footer,
            mdbMedia,
            mdbMediaImage,
            mdbMediaBody,
            mdbParallax,
            mdbMask,
            mdbAccordion,
            mdbBtn
        },
        data(){     
            return {
                panesPicture: [
                        {
                        title: '¿QUIÉNES SOMOS?',
                        content: `<div class="row">
                                    <div class="col-md-8 col-sm-12">
                                        <p class="text-justify au-text">Arvore es una empresa pionera en el ámbito ambiental, dedicada a desarrollar proyectos basados en la naturaleza que generan un impacto positivo en nuestro planeta. Trabajamos en estrecha colaboración con empresas, institutos de investigación, comunidades locales y otros interesados para impulsar la conservación de la biodiversidad, promover la restauración y conectividad del paisaje.</p>
                                    </div>
                                    <div class="col-md-4 col-sm-12" id="text-ar1">
                                        <div>
                                            <img src="/img/ada1.png" alt="" class="img-fluid">
                                        </div>
                                    </div>
                                </div>`
                        },{
                        title: '¿QUÉ HACEMOS?',
                        content: `<div class="row">
                                    <div class="col-md-4 col-sm-12" id="text-ar2">
                                        <div>
                                            <img src="/img/ada2.png" alt="" class="img-fluid">
                                        </div>
                                    </div>
                                    <div class="col-md-8 col-sm-12">
                                        <p class="text-justify au-text">Nuestro enfoque abarca desde la elaboración de proyectos de conectividad del paisaje y restauración ecológica, que permiten la regeneración de ecosistemas degradados y la recuperación de hábitats clave para la vida silvestre, hasta la realización de estudios ambientales en el sector energético como estudios de  lineas base hasta planificación de proyectos especificos que nos permiten comprender los impactos ambientales de las actividades energéticas y desarrollar soluciones sostenibles que minimicen dichos impactos.</p>
                                    </div>
                                </div>`
                        },{
                        title: 'PROYECTOS SOSTENIBLES: Adicionalidad frente al Cambio Climático',
                        content: `<div class="row">
                                    <div class="col-md-8 col-sm-12">
                                        <p class="text-justify au-text">Todos nuestros proyectos tienen el potencial de alinearse con la compensación, mitigación y el mercado de CO2 en términos de la regulación nacional e internacional. Buscamos no solo reducir las emisiones de gases de efecto invernadero, sino también brindar oportunidades y fomentar que las empresas y organizaciones compensen sus emisiones a través de la inversión en proyectos ambientales certificados. Esto permite la generación de créditos de carbono que contribuyen a mitigar el cambio climático (Net Zero) y promover la transición hacia una economía baja en carbono (Articulo 6 - Acuerdo de Paris).</p>
                                    </div>
                                    <div class="col-md-4 col-sm-12" id="text-ar3">
                                        <div>
                                            <img src="/img/ada3.png" alt="" class="img-fluid">
                                        </div>
                                    </div>
                                </div>`
                        },
                        {
                        title: 'NETWORKING: Conectando Ideas y Perspectivas',
                        content: `<div class="row">
                                    <div class="col-md-4 col-sm-12" id="text-ar4">
                                        <div>
                                            <img src="/img/ada4.png" alt="" class="img-fluid">
                                        </div>
                                    </div>
                                    <div class="col-md-8 col-sm-12">
                                        <p class="text-justify au-text">En Arvore, creemos en la sinergia de esfuerzos y en la importancia de trabajar de la mano con diferentes actores. Colaboramos estrechamente con empresas comprometidas con la responsabilidad ambiental, compartiendo nuestro conocimiento y experiencia para lograr resultados significativos. Además, involucramos a institutos de investigación y comunidades locales en la planificación y ejecución de nuestros proyectos, fomentando la participación activa y el respeto por el conocimiento tradicional.</p>
                                    </div>
                                </div>`
                        }
                    ]
            }
        }
    }
</script>
<style>
.accordion .card .card-header{
    background-color: #11619d !important;
}

.accord-text {
    display: block;
}

     @media(max-width: 770px){
        .au-text {
            font-size: 15px;
            line-height: 1rem;
        }

        #text-ar1 {
            display: none !important;
        }

        #text-ar2 {
            display: none !important;
        }

        #text-ar3 {
            display: none !important;
        }

        #text-ar4 {
            display: none !important;
        }
     }

     h5 span {
         font-size: 15px;
     }
</style>
